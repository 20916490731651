



































































































































































































import Vue from 'vue'
import getContactInfo from '~/apollo/queries/getContactInfo.gql'
import SvgImage from '~/components/SvgImage.vue'

export default Vue.extend({
  components: {
    SvgImage
  },
  async fetch () {
    const { data } = await this.$apolloProvider.defaultClient.query({
      query: getContactInfo
    })
    this.contactInfo = data.contactInfo[0]
  },
  data () {
    return {
      contactInfo: null,
      showVideo: false
    }
  },
  mounted () {
    const el = Array.from(document.getElementsByClassName('white-space-pre-line'))
    if (el) {
      el.forEach((e: any) => { e.innerHTML = e.innerHTML.replace(/^(\s)+/g, '') })
    }
  },
  methods: {
    openVideo () {
      this.showVideo = true
    },
    closeVideo () {
      this.showVideo = false
    },
    submit () {
      (this.$refs.form as any).submit()
    }
  }
})
